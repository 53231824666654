import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { adminLoginActions } from "../../../Store/Admin-slice";
import { loginActions } from "../../../Store/Auth-Slice";
import { data } from "./const";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "./FormProvider";

const Login = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { cardState } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSchema = Yup.object().shape({
    userName: Yup.string().required(`User Name is Required `),
    password: Yup.string().required(`password is Required `),
  });

  const methods = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: data,
  });

  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    if (values.userName === "admin" && values.password === "admin") {
      dispatch(loginActions.login());
      dispatch(adminLoginActions.login());
      navigate("/dashboard", { replace: true });
      return;
    }

    if (cardState === "Our Client" && values.userName === userInfo.userName && values.password === userInfo.password) {
      dispatch(loginActions.login());
      navigate("/dashboard", { replace: true });
    }
  };

  return (
    <Container>
      <Paper elevation={0}>
        <Button
          variant="contained"
          onClick={() => {
            if (cardState === "Our Client") {
              dispatch(loginActions.user({ userInfo: {} }));
              navigate("/ourClient", { replace: true });
            } else {
              navigate("/", { replace: true });
            }
          }}
        >
          {" "}
          Back{" "}
        </Button>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          px: 20,
          mt: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card sx={{ maxWidth: 545, position: "relative" }}>
          <CardMedia
            component="img"
            height="240"
            image={
              "https://www.aaawindows4less.com/wp-content/uploads/2016/11/stormy-windows-wide.jpg"
            }
            alt="clientImage"
            sx={{ color: "green" }}
          ></CardMedia>
          <Typography
            color={"white"}
            fontSize={24}
            sx={{ position: "absolute", top: "80px", left: "50px" }}
          >
            Sign In For Compliance Data
          </Typography>
          <CardContent>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="userName"
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      background: "#fcfcfc",
                      "& .Mui-focused": {
                        backgroundColor: "#fff",
                      },
                    }}
                    error={!!error}
                    helperText={error?.message}
                    label="userName"
                  />
                )}
              />
              <Controller
                name="password"
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      background: "#fcfcfc",
                      mt: 5,
                      "& .Mui-focused": {
                        backgroundColor: "#fff",
                      },
                    }}
                    error={!!error}
                    helperText={error?.message}
                    label="password"
                  />
                )}
              />
              <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </Box>
            </FormProvider>
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default Login;

import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  const handleCardClick = (path, cardName) => {
    navigate(path);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
        padding: "20px", 
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, 
          gap: 4,
        }}
      >
        <Card
          sx={{
            width: { xs: "100%", md: 200 }, 
            height: { xs: 100, md: 200 },
            border: "1px solid #2076d2",
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
            textAlign: "center",
            "&:hover": {
              borderColor: "#3f51b5",
            },
          }}
        >
          <CardActionArea
            onClick={() => handleCardClick("/ourClient", "Our Client")}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                fontSize={22}
                component="div"
              >
                Our Clients
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          sx={{
            width: { xs: "100%", md: 200 }, 
            height: { xs: 100, md: 200 },
            border: "1px solid #2076d2",
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
            textAlign: "center",
            "&:hover": {
              borderColor: "#3f51b5",
            },
          }}
        >
          <CardActionArea
            onClick={() => handleCardClick("/login", "Add Client")}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                fontSize={22}
                component="div"
              >
                Add Client
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </Paper>
  );
};

export default HomePage;
